import { HeadFC } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'

import { SEO } from '../components/SEO'
import { StaticImage } from 'gatsby-plugin-image'

const AboutPage = () => {
  return (
    <Layout>
      <div className="text-box">
        <div className="row">
          <div className="column">
            <h1>A propos</h1>
            <div className="image">
              <StaticImage
                src={'../images/about-1.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="about"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
                quality={100}
              />
            </div>
            <h2>Au départ</h2>

            <p>
              Je suis née d’une mère indienne et d’un père français. À l’âge de cinq ans, on me diagnostique une
              scoliose et une difficulté de latéralisation : le remède s’impose de lui même lorsque je vois un spectacle
              de bharata natyam. Je débute ainsi à Pondichéry, dans le style classique de l’école Kalakshetra, auprès de
              Shanty Rayapillai. Très tôt, je vibre du plaisir d’être sur scène et de cet échange avec le public. Le 19
              février 1997, après un an de travail quotidien, je présente mon arangetram, mes premiers pas sur scène, en
              duo avec le fils de mon enseignante, à l’Alliance Française de Pondichéry. Trois ans plus tard, je pousse
              la porte du cours de guru Mariapen, à l’époque tout jeune enseignant, qui est toujours mon maître
              aujourd’hui. Je reçois de lui la rigueur et l’intransigeance du bharata natyam, mais aussi un très fort
              sens de l’écoute : il chorégraphie minutieusement pour chacun de ses élèves, s’inspirant de la
              personnalité et de la présence uniques de chaque danseur.
            </p>

            <div className="image">
              <StaticImage
                src={'../images/about-2.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="about"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
                quality={100}
              />
              <figcaption>Photo : Kalpana Métayer</figcaption>
            </div>
          </div>
          <div className="column">
            <h2>Des rencontres qui me tissent</h2>

            <p>
              Les occasions de danser se multiplient, en troupe avec l’école de mon maître, dans des temples et lors de
              festivals hindous, ou en Europe, pour des rencontres artistiques et culturelles. En 2003, la danseuse
              baroque Véronique Elouard, de la compagnie Talon Pointe, m’invite à participer à sa nouvelle création,
              Escale insolite, au musée dauphinois de Grenoble, en compagnie de danseurs et comédiens d’autres horizons.
              C’est le début pour moi d’une longue histoire d’amour et de recherche avec la danse contemporaine et les
              autres styles en général, qui font écho à mon métissage. C’est aussi la découverte d’un rapport au corps
              du danseur différent, plus réfléchi et pensé, qui enrichit ma vision comme ma pratique du bharata natyam.
            </p>

            <p>
              En 2005, le bac en poche, je quitte l’Inde pour m’installer à Paris ; chaque retour en Inde, une à deux
              fois par an, est l’occasion d’un travail intensif auprès de mon maître. C’est aussi l’occasion de
              continuer à danser dans des temples, comme (souvenir inoubliable!) lors du Brahan Natyanjali de Tanjore en
              2009. En parallèle d’une classe prépa puis d’un master de géographie à la Sorbonne, je continue à
              travailler seule, ou en collaboration (avec la danseuse Renée Kurz, la poète Roselyne Sibille…). Un séjour
              d’un an et demi à Berlin en 2010-2011 étend encore mes rencontres ; c’est l’occasion de m’initier au butoh
              avec Imre Thorman, de recevoir deux chorégraphies contemporaines de la danseuse mexicaine Elizabeth Avila
              Leon, de travailler en improvisation avec des musiciens. Je continue à me produire, chorégraphie à mon
              tour et commence à enseigner.
            </p>

            <div className="image">
              <StaticImage
                src={'../images/about-3.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="about"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
                quality={100}
              />
              <figcaption>Photo : Irénée de Poulpiquet</figcaption>
            </div>

            <p>
              De retour en France, après mon mariage et l’arrivée de mon premier enfant, je rencontre Kalpana : je suis
              tout de suite touchée par son enseignement sobre et extrêmement précis qui nourrit mon besoin de retour
              aux bases, ses connaissances anatomiques qui me permettent de creuser ma technique, ainsi que son style de
              bharata natyam nouveau pour moi, celui de Muthuswamy Pillai dont elle a reçu l’enseignement. Son amitié et
              sa bienveillance m’ont permis de reprendre mes marques sur scène après la grande plongée dans la maternité
              ; j’ai découvert avec beaucoup d’enthousiasme que chaque événement de ma vie personnelle enrichit mon
              désir de danser et ma présence sur scène.
            </p>

            <p>
              Aujourd’hui, je continue à danser mon répertoire, à enseigner et à créer. Chose indispensable pour moi, je
              continue à me former et à chercher des sources d’inspiration et de croissance parmi les artistes dont
              l’approche me parle, en bharata natyam ou dans d’autres disciplines.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
export const Head: HeadFC = () => <SEO title="Lakshmi - A propos" pathname="/a-propos"></SEO>
